import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import SiteMetadataWrapper from '../components/SiteMetadataWrapper';
import shapeSiteMetadata from '../shapes/siteMetadata';

class NotFoundPage extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    siteMetadata: shapeSiteMetadata.isRequired,
  };

  componentDidMount () {
    window.document.body.className = '404';
  }

  render () {
    const {
      location,
      siteMetadata,
    } = this.props;
    return (
      <Layout
        location={ location }
        siteMetadata={ siteMetadata }
      >
        <section id="main" className="container medium">
          <header>
            <h2>Not Found</h2>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </header>
        </section>
      </Layout>
    );
  }
}

export default SiteMetadataWrapper(NotFoundPage);
